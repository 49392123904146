import './importCalculationPointsModal.scoped.scss'
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { ModalProps } from "services/modal/modalService"
import {
  ImportCalculationPointsForm,
  ImportCalculationPointsFormHandle,
} from "components/calculationTask/importCalculationPointsForm/importCalculationPointsForm"
import { CalculationPoint } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import React, {
  ChangeEvent,
  useRef,
  useState,
} from "react"
import { LightButton } from "components/common/buttons/lightButton/lightButton"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import {
  ImportUtmCalculationPointsForm,
} from "components/calculationTask/importUtmCalculationPointsForm/importUtmCalculationPointsForm"
import { ToolTip } from "components/common/toolTip/toolTip"
import { TooltipTriggerIcon } from "components/common/tooltipTriggerIcon/tooltipTriggerIcon"
import {
  ImportNtmCalculationPointsForm
} from "components/calculationTask/importNtmCalculationPointsForm/importNtmCalculationPointsForm"
import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"
import {
  CoordinateSystemSelectionField
} from "components/common/formFields/coordinateSystemSelectionField/coordinateSystemSelectionField"

export interface ImportCalculationPointsModalProps extends ModalProps {
  initialCalculationPoints?: CalculationPoint[]
  coordinateSystem: CoordinateSystem
}

export interface ImportCalculationPointsModalResult {
  success: boolean
  newCalculationPoints?: CalculationPoint[]
  coordinateSystem?: CoordinateSystem
}
export const ImportCalculationPointsModal = (props: ImportCalculationPointsModalProps) => {
  const formRef = useRef<ImportCalculationPointsFormHandle>(null)
  const [ coordinateSystem, setCoordinateSystem ] = useState<CoordinateSystem>(props.coordinateSystem)
  const [ formIsDirty, setFormIsDirty ] = useState<boolean>(false)


  const submit = () => {
    formRef.current?.submit()
  }

  const dismiss = () => {
    return props.modalContext.close({ success: false } as ImportCalculationPointsModalResult)
  }

  const onSubmit = (newCalculationPoints: CalculationPoint[]) => {
    return props.modalContext.close({
      success: true,
      newCalculationPoints,
      coordinateSystem,
    } as ImportCalculationPointsModalResult)
  }

  const onCoordinateSystemChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    setCoordinateSystem(event.target.value as CoordinateSystem)
    setFormIsDirty(false)
  }

  const buildForm = () => {
    if (coordinateSystem === CoordinateSystem.Utm) {
      return (
        <ImportUtmCalculationPointsForm
          ref={formRef}
          onSubmit={onSubmit}
          initialCalculationPoints={props.initialCalculationPoints ?? []}
          onDirtyStateChanged={setFormIsDirty}
        />
      )
    }
    if (coordinateSystem === CoordinateSystem.Ntm) {
      return (
        <ImportNtmCalculationPointsForm
          ref={formRef}
          onSubmit={onSubmit}
          initialCalculationPoints={props.initialCalculationPoints ?? []}
          onDirtyStateChanged={setFormIsDirty}
        />
      )
    }
    if (coordinateSystem === CoordinateSystem.Geographic) {
      return (
        <ImportCalculationPointsForm
          ref={formRef}
          onSubmit={onSubmit}
          initialCalculationPoints={props.initialCalculationPoints ?? []}
          onDirtyStateChanged={setFormIsDirty}
        />
      )
    }
  }

  return (
    <ModalBase
      onDismiss={dismiss}
      heading="Beregningspunkt"
      actions={
        <>
          <LightButton
            onClick={dismiss}
          >
          Avbryt
          </LightButton>
          <PrimaryButton
            onClick={submit}
          >
          Lagre
          </PrimaryButton>
        </>
      }
    >
      <div className="import-calculation-points-modal column gap4">
        <div className="row align-end">
          <CoordinateSystemSelectionField
            label={"Koordinatsystem"}
            value={coordinateSystem}
            isInvalid={false}
            onChange={onCoordinateSystemChanged}
            disabled={formIsDirty}
          />
          {formIsDirty && (
            <div className="form-dirty-warning row">
              <ToolTip
                content="Koordinatsystemet låses når du har gjort endringer i beregningspunktene"
              >
                <TooltipTriggerIcon />
              </ToolTip>
            </div>
          )}
        </div>
        {buildForm()}
      </div>
    </ModalBase>
  )
}

