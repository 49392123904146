import './importUtmCalculationPointsForm.scoped.scss'
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import {
  FormikErrors,
  FormikTouched,
  useFormik,
} from "formik"
import clipboard from "clipboardy"
import { toast } from "react-hot-toast"
import { parseUtmCoordinates } from "components/calculationTask/importCalculationPointsForm/parseClipboardContent"
import { CalculationPoint } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import papaParse from "papaparse"
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import { TextInput } from "components/common/formFields/textInput/textInput"
import {
  CalculationAltitudePicker,
} from "components/calculationTask/calculationAltitudePicker/calculationAltitudePicker"
import {
  ImportCalculationPointsFormHandle,
} from "components/calculationTask/importCalculationPointsForm/importCalculationPointsForm"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import {
  UtmToGeographicCoordinateConversionRequest,
} from "services/sicalcApi/requests/utmToGeographicCoordinateConversionRequest"
import { UtmHemisphere, UtmZone } from "services/sicalcApi/sharedEntities/utmCoordinates"
import { SelectField } from "components/common/formFields/selectField/selectField"
import {
  UtmCalculationPoint,
  UtmCalculationPointFormDataModel,
  utmCalculationPointValidationSchema,
} from "components/calculationTask/importUtmCalculationPointsForm/utmCalculationPointFormDataModel"
import {
  GeographicToUtmCoordinateConversionRequest,
} from "services/sicalcApi/requests/geographicToUtmCoordinateConversionRequest"
import { ToolTip } from "components/common/toolTip/toolTip"
import { TooltipTriggerIcon } from "components/common/tooltipTriggerIcon/tooltipTriggerIcon"
import { useMapService } from "services/mapService/mapService"
import { coereceToStringOrUndefined } from "utils/stringOperations"
import { useAppEditionService } from "services/appEdition/appEditionService"

export interface ImportUtmCalculationPointsFormProps {
  initialCalculationPoints: CalculationPoint[]
  onSubmit: (newCalculationPoints: CalculationPoint[]) => void
  onDirtyStateChanged: (isDirty: boolean) => void
}

export const _ImportUtmCalculationPointsForm = (props: ImportUtmCalculationPointsFormProps, ref: Ref<ImportCalculationPointsFormHandle>) => {
  const appEditionService = useAppEditionService()

  useImperativeHandle(ref, () => ({
    submit: form.submitForm,
  }))

  const [ initialValues, setInitialValues ] = useState<UtmCalculationPointFormDataModel>({
    calculationPoints: [],
    zone: undefined,
  })
  const [ conversionRequestIsPending, setConversionRequestIsPending ] = useState<boolean>(false)

  const { coordinateConversionApi } = useSicalcApiService()
  const mapService = useMapService()

  const handleSubmission = async (values: UtmCalculationPointFormDataModel) => {
    const formData = utmCalculationPointValidationSchema
      .cast(form.values) as Required<UtmCalculationPointFormDataModel>
    const request: UtmToGeographicCoordinateConversionRequest = {
      data: formData.calculationPoints.map(c => ({
        ...c,
        zone: formData.zone,
        hemisphere: UtmHemisphere.N,
      })),
    }
    const response = await coordinateConversionApi.convertUtmToGeographic(request)
    // TODO: The items in the response are not guaranteed to be in the same order as in the request
    const calculationPoints: CalculationPoint[] = response.data
      .map((item, index) => ({
        name: coereceToStringOrUndefined(values.calculationPoints[index].name),
        latitude: item.latitude,
        longitude: item.longitude,
        altitude: values.calculationPoints[index].altitude,
      }))
    props.onSubmit(calculationPoints)
  }

  const form = useFormik({
    initialValues,
    validationSchema: utmCalculationPointValidationSchema,
    onSubmit: handleSubmission,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true,
  })

  const getEmptyRow = () => (      {
    name: undefined,
    easting: '' as unknown as number,
    northing: '' as unknown as number,
    altitude: undefined,
  })

  const reInitializeWithEmptyRow = (zone?: number) => {
    setInitialValues({
      zone: zone,
      calculationPoints: [getEmptyRow()],
    })
  }

  useEffect(() => {
    props.onDirtyStateChanged(form.dirty)
  }, [form.dirty])

  const resetForm = async () => {
    form.resetForm()
  }

  const convertInitialPoints = async (utmZone: number) => {
    setConversionRequestIsPending(true)
    const request: GeographicToUtmCoordinateConversionRequest = {
      zone: utmZone,
      hemisphere: UtmHemisphere.N,
      data: props.initialCalculationPoints.map(c => ({
        latitude: c.latitude,
        longitude: c.longitude,
      })),
    }
    try {
      const response = await coordinateConversionApi.convertGeographicToUtm(request)
      // TODO: The items in the response are not guaranteed to be in the same order as in the request
      const result = response.data.map((item, index) => ({
        name: props.initialCalculationPoints[index].name,
        easting: item.easting,
        northing: item.northing,
        altitude: props.initialCalculationPoints[index].altitude,
      }))

      return result
    } finally {
      setConversionRequestIsPending(false)
    }
  }

  const onPasteButtonClicked = async () => {
    let text: string
    try {
      text = await clipboard.read()
    } catch {
      toast.error("Fikk ikke tilgang til utklippstavlen. Du kan lime en tabell inn i en celle i stedet")
      return
    }
    const calculationPoints = parseUtmCoordinates(text)

    if (calculationPoints === null) {
      toast.error("Fant ingen beregningspunkt i utklippstavlen. Sjekk at det er 4 kolonner og korrekt format.")
      return
    }

    addCalculationPoints(calculationPoints, 0)
  }

  const onPasteInTextInput = (event: React.ClipboardEvent, rowIndex: number) => {
    const clipboardContent = event.clipboardData.getData("text")
    const calculationPoints = parseUtmCoordinates(clipboardContent)

    if (calculationPoints === null) {
      return
    }

    event.preventDefault()
    addCalculationPoints(calculationPoints, rowIndex)
  }

  const addCalculationPoints = (calculationPoints: UtmCalculationPoint[], startingRowIndex: number) => {
    const existingItemsBeforeCurrentRow = form.values.calculationPoints.slice(0, startingRowIndex)
    const existingItemsAfterCurrentRow = form.values.calculationPoints.slice(startingRowIndex + calculationPoints.length)
    form.setFieldValue('calculationPoints', [
      ...existingItemsBeforeCurrentRow,
      ...calculationPoints,
      ...existingItemsAfterCurrentRow,
    ])
  }

  const addFormRow = () => {
    form.setFieldValue('calculationPoints', [
      ...form.values.calculationPoints,
      getEmptyRow(),
    ])
  }

  const onZoneChanged = async (newZone: number) => {
    // Update the value in the form field for user feedback, but using resetForm to avoid a flash of dirty state
    form.resetForm({
      values: {
        zone: newZone,
        calculationPoints: form.values.calculationPoints,
      },
    })

    if (props.initialCalculationPoints.length > 0) {
      const initialPoints = await convertInitialPoints(newZone)
      setInitialValues({
        zone: newZone,
        calculationPoints: initialPoints,
      })
    } else {
      reInitializeWithEmptyRow(newZone)
    }
  }

  const getUtmZoneOfMapCenter = () => {
    const mapCenter = mapService.getMapCenter()
    const zone = Math.ceil((180 + mapCenter.longitude) / 6)
    // TODO: Implement the extension of zone 32 into zone 31 that's special for Norway
    return zone
  }

  useEffect(() => {
    if (form.values.zone === undefined) {
      const zone = getUtmZoneOfMapCenter()
      onZoneChanged(zone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeFormRow = (index: number) => {
    const { calculationPoints } = form.values
    const newCalculationPoints = [...calculationPoints]
    newCalculationPoints.splice(index, 1)
    form.setFieldValue('calculationPoints', newCalculationPoints)
  }

  const copyToClipboard = async () => {
    let text: string
    try {
      text = papaParse.unparse(form.values.calculationPoints, {
        delimiter: "\t",
        header: false,
      })
    } catch {
      toast.error("Fikk ikke tilgang til utklippstavlen")
      return
    }

    await clipboard.write(text)
  }

  const getRowTouchedStatus = (index: number) => {
    if (!form.touched.calculationPoints) {
      return undefined
    }

    return form.touched.calculationPoints[index] as FormikTouched<UtmCalculationPoint>
  }

  const getRowErrors = (index: number) => {
    if (!form.errors.calculationPoints) {
      return undefined
    }

    return form.errors.calculationPoints[index] as FormikErrors<UtmCalculationPoint>
  }

  return (
    <form className="column gap4" onSubmit={form.handleSubmit}>
      <div className="zone-dropdown-wrapper row align-end">
        <SelectField
          label={"Sone"}
          value={form.values.zone}
          name={"zone"}
          onChange={(e) => onZoneChanged(Number(e.target.value))}
          isInvalid={!!form.touched.zone && !!form.errors.zone}
          errors={form.errors.zone}
          options={
            Object.values(UtmZone)
              .filter(item => !isNaN(Number(item))) // Eliminate the keys
              .filter(item => appEditionService.settings.coordinates.accessibleUtmZones.includes(item as UtmZone))
              .map(z => ({
                name: z as string,
                value: z as number,
              }))}
          disabled={conversionRequestIsPending || form.dirty}
        />
        {form.dirty && (
          <div className="form-dirty-warning row">
            <ToolTip
              content="Sonen låses når du har gjort endringer i beregningspunktene"
            >
              <TooltipTriggerIcon />
            </ToolTip>
          </div>
        )}
        {conversionRequestIsPending && (
          <div className="mb3 ml4">Omformer ...</div>
        )}
      </div>
      {form.values.zone === undefined && !conversionRequestIsPending && (
        <div>Velg sone for å fortsette</div>
      )}
      {form.values.zone !== undefined && (
        <>
          <div className="row gap4">
            <ClearButton iconName="content-copy" onClick={copyToClipboard}>Kopier</ClearButton>
            <ClearButton iconName="content-paste" onClick={onPasteButtonClicked}>Lim inn</ClearButton>
            <ClearButton iconName="restore" onClick={resetForm} disabled={!form.dirty}>Tilbakestill</ClearButton>
            <ClearButton
              iconName="delete"
              onClick={() => form.setFieldValue('calculationPoints', [])}
              disabled={!form.values.calculationPoints.length}
            >Slett alle</ClearButton>
          </div>
          {form.values.calculationPoints.length > 0 && (
            <div className="calculation-point-input-form">
              <div className="table-head">
                <span className="table-head-cell">Navn</span>
                <span className="table-head-cell">Øst</span>
                <span className="table-head-cell">Nord</span>
                <span className="table-head-cell">Høyde (m)</span>
              </div>
              {React.Children.toArray(form.values.calculationPoints.map((c, index) => (
                <div className="table-row">

                  <div className="table-cell">
                    <TextInput
                      name={`calculationPoints[${index}].name`}
                      value={c.name ?? ''}
                      isInvalid={!!getRowTouchedStatus(index)?.name && !!getRowErrors(index)?.name}
                      errors={getRowErrors(index)?.name}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      onPaste={event => onPasteInTextInput(event, index)}
                    />
                  </div>

                  <div className="table-cell">
                    <TextInput
                      name={`calculationPoints[${index}].easting`}
                      value={c.easting}
                      isInvalid={!!getRowTouchedStatus(index)?.easting && !!getRowErrors(index)?.easting}
                      errors={getRowErrors(index)?.easting}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      onPaste={event => onPasteInTextInput(event, index)}
                    />
                  </div>

                  <div className="table-cell">
                    <TextInput
                      name={`calculationPoints[${index}].northing`}
                      value={c.northing}
                      isInvalid={!!getRowTouchedStatus(index)?.northing && !!getRowErrors(index)?.northing}
                      errors={getRowErrors(index)?.northing}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      onPaste={event => onPasteInTextInput(event, index)}
                    />
                  </div>

                  <div className="table-cell">
                    <CalculationAltitudePicker
                      value={c.altitude ?? 4}
                      onChange={value => form.setFieldValue(`calculationPoints[${index}].altitude`, value)}
                    />
                  </div>
                  <span className="ml4 mr1">
                    <ClearButton
                      onClick={() => removeFormRow(index)}
                      iconName="delete"
                      text="Slett"
                    />
                  </span>

                </div>
              )))}
            </div>
          )}
          <div className="row">
            <ClearButton
              iconName={"plus"}
              text="Legg til punkt"
              onClick={addFormRow}
            />
          </div>
        </>
      )}
    </form>
  )
}

export const ImportUtmCalculationPointsForm = forwardRef(_ImportUtmCalculationPointsForm)
